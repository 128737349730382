exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-regionen-js": () => import("./../../../src/pages/regionen.js" /* webpackChunkName: "component---src-pages-regionen-js" */),
  "component---src-pages-staedte-js": () => import("./../../../src/pages/staedte.js" /* webpackChunkName: "component---src-pages-staedte-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-nationalpark-js": () => import("./../../../src/templates/nationalpark.js" /* webpackChunkName: "component---src-templates-nationalpark-js" */)
}

